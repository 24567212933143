// Copyright Northcote Technology Ltd
import { cloneDeep } from 'lodash'

import {
  CREATE_RECORDING,
  DELETE_RECORDING,
  DELETE_SESSION_SUCCESS,
  FETCH_SESSION_SUCCESS,
  SET_OVERALL_GRADING_OPTION,
  UPDATE_GRADINGS,
  UPDATE_RECORDING,
  UPDATE_SESSION,
  UPDATE_SESSION_CUSTOM_FIELD,
  UPDATE_SESSION_DEVICE_TYPE,
  UPDATE_SESSION_RESULT_FLEET_IDS,
  UPDATE_SESSION_RESULT_POSITION,
  UPDATE_SESSION_RESULT_SIGNATURE_DATA,
  UPDATE_SESSION_RESULT_ABSTRACT,
  OFFLINE_SESSIONS_SYNCED,
} from '../../actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_RECORDING: {
      const { recording, sessionId } = action
      const session = state[sessionId]
      const newSession = {
        ...session,
        recordings: session.recordings.concat([recording]),
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case DELETE_RECORDING: {
      const { recording, sessionId } = action
      const session = state[sessionId]
      const newSession = {
        ...session,
        recordings: session.recordings.filter(({ id }) => id !== recording.id),
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case DELETE_SESSION_SUCCESS: {
      const newState = { ...state }
      delete newState[action.id]
      return newState
    }
    case FETCH_SESSION_SUCCESS: {
      const { payload } = action

      return {
        ...state,
        [payload.id]: payload,
      }
    }
    case SET_OVERALL_GRADING_OPTION: {
      const { sessionId, sessionResultId, value } = action
      const newSession = cloneDeep(state[sessionId])

      newSession.gradingSessionResults.forEach(result => {
        if (result.id === sessionResultId) {
          result.overallGradingOptionId = value
        }
      })

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case UPDATE_GRADINGS: {
      const { gradings, sessionId } = action
      const newSession = cloneDeep(state[sessionId])

      gradings.forEach(grading => {
        const { gradingSessionResultId, id } = grading

        newSession.gradingSessionResults.forEach(result => {
          if (result.id !== gradingSessionResultId) return

          // Directly mutate the cloned data.
          result.gradings = result.gradings.map(existingGrading =>
            existingGrading.id === id ? grading : existingGrading
          )
        })
      })

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case UPDATE_RECORDING: {
      const { recording, sessionId } = action
      const session = state[sessionId]
      const newRecordings = session.recordings.map(existingRecording =>
        existingRecording.id === recording.id ? recording : existingRecording
      )
      const newSession = {
        ...session,
        recordings: newRecordings,
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case UPDATE_SESSION: {
      const { id, params } = action
      const existingSession = state[id]
      const newSession = {
        ...existingSession,
        ...params,
      }

      return {
        ...state,
        [id]: newSession,
      }
    }
    case UPDATE_SESSION_CUSTOM_FIELD: {
      const { sessionId, fieldId, value } = action
      const existingSession = state[sessionId]

      const updatedCustomFields =
        existingSession.gradingSessionCustomFields.map(field =>
          field.customFieldId === fieldId
            ? { ...field, fieldTypeValue: value }
            : field
        )

      const updatedSession = {
        ...existingSession,
        gradingSessionCustomFields: updatedCustomFields,
      }

      return {
        ...state,
        [sessionId]: updatedSession,
      }
    }
    case UPDATE_SESSION_DEVICE_TYPE: {
      const { sessionId, deviceTypeId } = action
      const existingSession = state[sessionId]

      const updatedSession = {
        ...existingSession,
        deviceTypeId,
        gradingSessionResults: existingSession.gradingSessionResults.map(
          result => ({
            ...result,
            position: null,
          })
        ),
      }

      return {
        ...state,
        [sessionId]: updatedSession,
      }
    }
    case UPDATE_SESSION_RESULT_FLEET_IDS: {
      const { fleetIds, resultId, sessionId } = action
      const session = state[sessionId]
      const { gradingSessionResults } = session
      const newGradingSessionResults = gradingSessionResults.map(
        gradingSessionResult => {
          if (gradingSessionResult.id === resultId) {
            return {
              ...gradingSessionResult,
              fleetIds,
            }
          } else {
            return gradingSessionResult
          }
        }
      )
      const newSession = {
        ...session,
        gradingSessionResults: newGradingSessionResults,
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case UPDATE_SESSION_RESULT_SIGNATURE_DATA: {
      const { resultId, sessionId, signatureData } = action
      const session = state[sessionId]
      const newGradingSessionResults = session.gradingSessionResults.map(
        gradingSessionResult => {
          if (gradingSessionResult.id === resultId) {
            return {
              ...gradingSessionResult,
              signatureData,
              signatureUrl: null,
            }
          } else {
            return gradingSessionResult
          }
        }
      )
      const newSession = {
        ...session,
        gradingSessionResults: newGradingSessionResults,
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case UPDATE_SESSION_RESULT_POSITION: {
      const { position, resultId, sessionId } = action
      const session = state[sessionId]
      const { gradingSessionResults } = session
      const newGradingSessionResults = gradingSessionResults.map(
        gradingSessionResult => {
          if (gradingSessionResult.id === resultId) {
            return {
              ...gradingSessionResult,
              position,
            }
          } else {
            return gradingSessionResult
          }
        }
      )
      const newSession = {
        ...session,
        gradingSessionResults: newGradingSessionResults,
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case UPDATE_SESSION_RESULT_ABSTRACT: {
      const { abstract, resultId, sessionId } = action
      const session = state[sessionId]
      const { gradingSessionResults } = session
      const newGradingSessionResults = gradingSessionResults.map(
        gradingSessionResult => {
          if (gradingSessionResult.id === resultId) {
            return {
              ...gradingSessionResult,
              abstract,
            }
          } else {
            return gradingSessionResult
          }
        }
      )
      const newSession = {
        ...session,
        gradingSessionResults: newGradingSessionResults,
      }

      return {
        ...state,
        [sessionId]: newSession,
      }
    }
    case OFFLINE_SESSIONS_SYNCED: {
      const offlineSessionIds = Object.keys(state).filter(i => i < 0)
      offlineSessionIds.forEach(sessionId => {
        delete state[sessionId] // remove offline sessions from state
      })
      return state
    }
    default: {
      return state
    }
  }
}
