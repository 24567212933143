// Copyright Northcote Technology Ltd
import { get_request } from 'src/lib/requestToServer'

import {
  CREATE_RECORDING,
  DELETE_RECORDING,
  DELETE_SESSION_SUCCESS,
  UPDATE_RECORDING,
  RESOLVED_GET_COMPLIANCE_ITEM,
  GET_EDIT_STATE,
  GET_CREATE_STATE,
  UPDATE_COMPLIANCE_ITEM,
  UPDATE_PEOPLE_TABLE,
  RESOLVED_GET_PERSON_ITEM,
  FLEXLAYOUT_TAB_SELECTED,
  SET_BOTTOM_BUTTONS,
  BOTTOM_BUTTON_CLICKED,
  IS_MAXIMIZED,
  SET_PROGRESS,
  UPDATE_MY_SETTINGS_TABLE,
  RESOLVED_GET_MY_SETTINGS_ITEM,
  UPDATE_HEATMAP_REPORTS_ITEM,
  UPDATE_HEATMAP_REPORTS_TABLE,
  RESOLVED_GET_HEATMAP_REPORTS_ITEM,
  PERSON_DELETED,
  SET_ONLINE,
  FETCH_SESSION,
  FETCH_SESSION_SUCCESS,
  SAVE_SESSION,
  SAVE_SESSION_SUCCESS,
  SET_OVERALL_GRADING_OPTION,
  SET_RESULTS_ERRORS,
  SET_SESSION_ERRORS,
  UPDATE_GRADINGS,
  UPDATE_SESSION,
  UPDATE_SESSION_DEVICE_TYPE,
  UPDATE_SESSION_CUSTOM_FIELD,
  UPDATE_SESSION_RESULT_FLEET_IDS,
  UPDATE_SESSION_RESULT_POSITION,
  UPDATE_SESSION_RESULT_SIGNATURE_DATA,
  UPDATE_SESSION_RESULT_ABSTRACT,
  OFFLINE_SESSIONS_SYNCED,
} from './actionTypes'

export const setEditStateTrue = () => {
  return function (dispatch) {
    return dispatch(getEditState(true))
  }
}

export const setEditStateFalse = () => {
  return function (dispatch) {
    return dispatch(getEditState(false))
  }
}

export const setCreateStateTrue = () => {
  return function (dispatch) {
    return dispatch(getCreateState(true))
  }
}

export const setCreateStateFalse = () => {
  return function (dispatch) {
    return dispatch(getCreateState(false))
  }
}

export const selectComplianceItem = node => {
  return function (dispatch) {
    return get_request('/admin/compliance', { id: node.id }).then(function (
      response
    ) {
      dispatch(resolvedGetComplianceItem(response))
    })
  }
}

export const getMySettingsUpdate = () => {
  return function (dispatch) {
    return get_request('/my_settings', null).then(function (response) {
      dispatch(updateMySettingsTable(response))
    })
  }
}

export const getMySettingsItem = node => {
  return function (dispatch) {
    return get_request('/my_settings', { id: node }).then(function (response) {
      dispatch(resolvedGetMySettingsItem(response))
    })
  }
}
export const setMySettingsItem = node => {
  return function (dispatch) {
    return dispatch(resolvedGetMySettingsItem(node))
  }
}
export const updateMySettingsTable = data => {
  return {
    type: UPDATE_MY_SETTINGS_TABLE,
    payload: { data },
  }
}

export const getPeopleUpdate = () => {
  // Disabled. Pending to have a solution for large sets
  return function (/*dispatch*/) {
    //  return get_request('/admin/people', null)
    //    .then(function (response) {
    //      dispatch(updatePeopleTable(response))
    //    })
  }
}

export const personDeleted = personId => {
  return {
    type: PERSON_DELETED,
    payload: { personId },
  }
}

export const setPersonItem = node => {
  return function (dispatch) {
    dispatch(updatePeopleTable(node))
    return dispatch(resolvedGetPersonItem(node))
  }
}

export const updatePeopleTable = data => {
  return {
    type: UPDATE_PEOPLE_TABLE,
    payload: { data },
  }
}

export const getHeatmapReportsUpdate = () => {
  return function (dispatch) {
    return get_request('/heatmap_reports', null).then(function (response) {
      dispatch(updateHeatmapReportsTable(response))
    })
  }
}

export const getHeatmapReportsItem = node => {
  return function (dispatch) {
    return get_request('/heatmap_reports', { id: node }).then(function (
      response
    ) {
      dispatch(resolvedGetHeatmapReportsItem(response))
    })
  }
}

export const getHeatmapReportsVisualisationItem = node => {
  return function (dispatch) {
    return get_request('/heatmap_reports', {
      id: node.id,
      visualisation: true,
    }).then(function (response) {
      dispatch(resolvedGetHeatmapReportsItem(response))
    })
  }
}

export const setHeatmapReportsItem = node => {
  return function (dispatch) {
    return dispatch(resolvedGetHeatmapReportsItem(node))
  }
}

export const updateHeatmapReportsTable = data => {
  return {
    type: UPDATE_HEATMAP_REPORTS_TABLE,
    payload: { data },
  }
}

export const getEditState = data => {
  return {
    type: GET_EDIT_STATE,
    payload: { data },
  }
}

export const getCreateState = data => {
  return {
    type: GET_CREATE_STATE,
    payload: { data },
  }
}

export const resolvedGetPersonItem = data => {
  return {
    type: RESOLVED_GET_PERSON_ITEM,
    payload: { data },
  }
}

export const resolvedGetComplianceItem = data => {
  return {
    type: RESOLVED_GET_COMPLIANCE_ITEM,
    payload: { data },
  }
}

export const resolvedGetMySettingsItem = data => {
  return {
    type: RESOLVED_GET_MY_SETTINGS_ITEM,
    payload: { data },
  }
}

export const resolvedGetHeatmapReportsItem = data => {
  return {
    type: RESOLVED_GET_HEATMAP_REPORTS_ITEM,
    payload: { data },
  }
}

export const isMaximized = maximized => {
  return {
    type: IS_MAXIMIZED,
    payload: { maximized },
  }
}

export const updateComplianceItem = data => {
  return {
    type: UPDATE_COMPLIANCE_ITEM,
    payload: { data },
  }
}

export const updateHeatmapReportsItem = data => {
  return {
    type: UPDATE_HEATMAP_REPORTS_ITEM,
    payload: { data },
  }
}

export const flexlayoutTabSelected = tabSelected => {
  return {
    type: FLEXLAYOUT_TAB_SELECTED,
    payload: { tabSelected },
  }
}

export const setBottomButtons = buttons => {
  return {
    type: SET_BOTTOM_BUTTONS,
    payload: { buttons },
  }
}

export const bottomButtonClicked = button => {
  return {
    type: BOTTOM_BUTTON_CLICKED,
    payload: { button },
  }
}

export const setProgress = progress => {
  return {
    type: SET_PROGRESS,
    payload: { progress },
  }
}

export function createRecording(sessionId, recording) {
  return { recording, sessionId, type: CREATE_RECORDING }
}

export function deleteRecording(sessionId, recording) {
  return { recording, sessionId, type: DELETE_RECORDING }
}

export function deleteSessionSuccess(id) {
  return { id, type: DELETE_SESSION_SUCCESS }
}

export function fetchSession(id) {
  return { id, type: FETCH_SESSION }
}

export function fetchSessionSuccess(data) {
  return { payload: data, type: FETCH_SESSION_SUCCESS }
}

export function saveSession(id) {
  return { id, type: SAVE_SESSION }
}

export function saveSessionSuccess(id) {
  return { payload: { id }, type: SAVE_SESSION_SUCCESS }
}

export function setOnline(online) {
  // Maintain current UBF.online compatibilty for now.
  if (window.UBF) {
    window.UBF.online = online
  }

  return { online, type: SET_ONLINE }
}

export function setOverallGradingOption(sessionId, sessionResultId, value) {
  return {
    sessionId,
    sessionResultId,
    type: SET_OVERALL_GRADING_OPTION,
    value,
  }
}

export function setResultsErrors(errors) {
  return { errors, type: SET_RESULTS_ERRORS }
}

export function setSessionErrors(sessionId, errors) {
  return { errors, sessionId, type: SET_SESSION_ERRORS }
}

export function updateGradings(sessionId, gradings) {
  return { gradings, sessionId, type: UPDATE_GRADINGS }
}

export function updateRecording(sessionId, recording) {
  return { recording, sessionId, type: UPDATE_RECORDING }
}

export function updateSession(id, params) {
  return { id, params, type: UPDATE_SESSION }
}

export function updateSessionCustomField(sessionId, fieldId, value) {
  return {
    sessionId,
    fieldId,
    value,
    type: UPDATE_SESSION_CUSTOM_FIELD,
  }
}

export function updateSessionDeviceType(sessionId, deviceTypeId) {
  return {
    sessionId,
    deviceTypeId,
    type: UPDATE_SESSION_DEVICE_TYPE,
  }
}

export function updateSessionResultFleetIds(sessionId, resultId, fleetIds) {
  return {
    fleetIds,
    resultId,
    sessionId,
    type: UPDATE_SESSION_RESULT_FLEET_IDS,
  }
}

export function updateSessionResultPosition(sessionId, resultId, position) {
  return {
    position,
    resultId,
    sessionId,
    type: UPDATE_SESSION_RESULT_POSITION,
  }
}

export function updateSessionResultSignatureData(
  sessionId,
  resultId,
  signatureData
) {
  return {
    resultId,
    sessionId,
    signatureData,
    type: UPDATE_SESSION_RESULT_SIGNATURE_DATA,
  }
}

export function updateSessionResultAbstract(sessionId, resultId, abstract) {
  return {
    abstract,
    resultId,
    sessionId,
    type: UPDATE_SESSION_RESULT_ABSTRACT,
  }
}

export function offlineSessionsSynced() {
  return { type: OFFLINE_SESSIONS_SYNCED }
}
