// Copyright Northcote Technology Ltd
import camelize from 'camelize'
import moment from 'moment'
import { isEmpty } from 'lodash'

import hasSomeEmpty from '../hasSomeEmpty'
import translate from 'src/lib/translate'
import {
  getTheoricalOptionId,
  isRuleMet,
  tallyGradingCategories,
  optionHasRule,
} from '../overallGradingHelper'

const GRADING_ERRORS = {
  beforeApplicableFrom:
    'activerecord.errors.models.grading.attributes.applicablePeriod.beforeApplicableFromDate',
  behaviour:
    'activerecord.errors.models.grading.attributes.grade.mandatoryBehaviour',
  fromDate:
    'activerecord.errors.models.grading.attributes.applicableFromDate.presence',
  info: 'activerecord.errors.models.grading.attributes.grade.mandatoryAdditionalInfo',
  missingGrade: 'activerecord.errors.models.grading.attributes.grade.missing',
  period:
    'activerecord.errors.models.grading.attributes.applicablePeriod.presence',
  upload: 'activerecord.errors.models.grading.attributes.grade.mandatoryUpload',
}

const setMessage = (type, errors) => errors.push(GRADING_ERRORS[type])

function checkGradingValidations(grading, gradingScale) {
  const errors = []

  if (grading.incomplete) return errors

  if ((grading.grade == '' || !grading.grade) && !grading.deferred) {
    setMessage('missingGrade', errors)
    return errors
  }

  if (!grading.category) return errors

  var category = grading.category

  if (
    ((category == 'acceptable' &&
      gradingScale.mandatoryAdditionalInfoForAcceptable) ||
      (category == 'disqualifying' &&
        gradingScale.mandatoryAdditionalInfoForDisqualifying) ||
      (category == 'exemplary' &&
        gradingScale.mandatoryAdditionalInfoForExemplary) ||
      (category == 'failing' &&
        gradingScale.mandatoryAdditionalInfoForFailing) ||
      (category == 'minimum_acceptable' &&
        gradingScale.mandatoryAdditionalInfoForMinimumAcceptable) ||
      (category == 'other' && gradingScale.mandatoryAdditionalInfoForOther)) &&
    hasSomeEmpty(grading.additionalInfo)
  ) {
    setMessage('info', errors)
  }

  if (
    ((category == 'acceptable' &&
      gradingScale.mandatoryBehaviourForAcceptable) ||
      (category == 'disqualifying' &&
        gradingScale.mandatoryBehaviourForDisqualifying) ||
      (category == 'exemplary' &&
        gradingScale.mandatoryBehaviourForExemplary) ||
      (category == 'failing' && gradingScale.mandatoryBehaviourForFailing) ||
      (category == 'minimum_acceptable' &&
        gradingScale.mandatoryBehaviourForMinimumAcceptable) ||
      (category == 'other' && gradingScale.mandatoryBehaviourForOther)) &&
    grading.observations.length == 0
  ) {
    setMessage('behaviour', errors)
  }

  if (
    ((category == 'acceptable' && gradingScale.mandatoryUploadForAcceptable) ||
      (category == 'disqualifying' &&
        gradingScale.mandatoryUploadForDisqualifying) ||
      (category == 'exemplary' && gradingScale.mandatoryUploadForExemplary) ||
      (category == 'failing' && gradingScale.mandatoryUploadForFailing) ||
      (category == 'minimum_acceptable' &&
        gradingScale.mandatoryUploadForMinimumAcceptable) ||
      (category == 'other' && gradingScale.mandatoryUploadForOther)) &&
    grading.files.length == 0
  ) {
    setMessage('upload', errors)
  }

  if (
    category &&
    !grading.applicableFromDate &&
    gradingScale.mandatoryApplicableFromDate
  ) {
    setMessage('fromDate', errors)
  }

  if (category) {
    if (
      !['failing', 'disqualifying', 'other'].includes(category) &&
      !grading.applicableUntilDate &&
      gradingScale.mandatoryApplicablePeriod
    ) {
      setMessage('period', errors)
    }
  }

  if (grading.applicableFromDate) {
    var applicableFromDate = moment(grading.applicableFromDate)
    var applicableUntilDate = moment(grading.applicableUntilDate)

    if (
      !['failing', 'disqualifying', 'other'].includes(category) &&
      applicableUntilDate.diff(applicableFromDate, 'days') < 0
    ) {
      setMessage('beforeApplicableFrom', errors)
    }
  }

  return errors
}

export function gradingErrors(translations, gradingScale, grading) {
  if (gradingScale) {
    return checkGradingValidations(grading, gradingScale)
      .map(path => translate(path, translations))
      .join('. ')
  }
}

export function overallGradingRulesCompliant(session) {
  if (!session.gradingSessionTemplate.overallGrading) return true //overall not required
  const options =
    session.gradingSessionTemplate.overallGrading.overallGradingOptions

  let compliant = true
  session.gradingSessionResults.forEach(result => {
    const selectedOption = options.find(
      o => o.id == result.overallGradingOptionId
    )
    if (!selectedOption) {
      compliant = false
      return
    }
    const gradingCategories = tallyGradingCategories(result.gradings)
    const theoricalOptionId = getTheoricalOptionId(options, gradingCategories)
    compliant =
      compliant &&
      (theoricalOptionId == null ||
        result.overallGradingOptionId === theoricalOptionId) &&
      (!optionHasRule(selectedOption) ||
        isRuleMet(selectedOption, gradingCategories))
  })
  return compliant
}

export function checkSession(translations, session) {
  const errors = {}
  const messages = []

  if (!moment().isAfter(session.gradedDate)) {
    messages.push(
      translations.activerecord.errors.models.grading.attributes.grade
        .future_graded_date
    )
  }

  const {
    customFieldsData,
    deviceTypeId,
    gradingScalesData,
    gradingSessionCustomFields,
    gradingSessionResults,
    gradingSessionTemplate: { deviceTypes },
    qualificationsData,
  } = session

  if (deviceTypes.length > 0 && !deviceTypeId) {
    errors.deviceTypeId =
      translations.activerecord.errors.models.grading_session.mandatory.device_type
  }
  gradingSessionCustomFields.forEach(({ customFieldId, fieldTypeValue }) => {
    const customField = customFieldsData[customFieldId]

    if (!customField) return

    if (isEmpty(fieldTypeValue)) {
      const errorMessageTemplate =
        translations.activerecord.errors.models.grading_session.mandatory
          .custom_field
      errors[`customFieldId-${customFieldId}`] = errorMessageTemplate.replace(
        '%{custom_field_name}',
        customField.name
      )
    }
  })

  const camelizedTranslations = camelize(translations)

  gradingSessionResults.forEach(result => {
    result.gradings.forEach(grading => {
      const qualification = qualificationsData[grading.qualificationId]
      const gradingScale = gradingScalesData[qualification.gradingScaleId]
      const msg = gradingErrors(camelizedTranslations, gradingScale, grading)

      if (msg) messages.push(msg)
    })
  })

  if (!overallGradingRulesCompliant(session)) {
    messages.push(
      translations.messages.grading_session_results.overall_grading_mismatch
    )
  }

  return [messages, errors]
}

export function checkSessionResults(translations, session) {
  const { gradingSessionResults, gradingSessionTemplate } = session
  const errors = {}

  gradingSessionResults.forEach(result => {
    const resultErrors = {}

    if (gradingSessionTemplate.signatureRequired) {
      const { signatureData, signatureUrl } = result

      if (!signatureData && !signatureUrl) {
        resultErrors.signature =
          translations.activerecord.errors.models.grading_session_result.attributes.signature.blank
      }
    }

    if (
      gradingSessionTemplate.fleets.length > 0 &&
      result.fleetIds.length === 0
    ) {
      resultErrors.fleetIds =
        translations.activerecord.errors.models.grading_session_result.attributes.fleets.blank
    }

    if (!isEmpty(resultErrors)) {
      errors[result.id] = resultErrors
    }
  })

  return errors
}
